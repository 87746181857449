import { ref, reactive } from 'vue'
import type { Ref } from 'vue'

import { api } from '@/main'
import { AiBbox, StepStepStatus } from '@/api/steps'
import { AiProcedure } from '@/api/procedures'
import { AiInference, AiStepDetection, AiStepEvent, AiTrack } from '@/api/inferences'
import { getMedianValue } from '@/components/InferencePlayer/InferencePlayer.utils'

export interface TimestampedInference {
  timestamp: number,
  frames: {
    step: string,
    color: string,
    label: string,
    bbox: AiBbox | {
      x: number,
      y: number,
      width: number,
      height: number,
    },
  }[],
}
export interface TimestampedAiStepEvent extends AiStepEvent {
  timestamp: number;
}

export interface TimestampedActiveStep {
  timestamp: number
  step: string
}

export interface FrameMetadata {
  expectedDisplayTime: number,
  mediaTime: number,
  presentationTime: number,
  presentedFrames: number,
  processingDuration?: number,
  width: number,
  height: number,
}

type VideoFrameRequestCallback = (now: number, metadata: FrameMetadata) => void
type HTMLVIDEOElement = HTMLVideoElement & { requestVideoFrameCallback: (callback: VideoFrameRequestCallback) => void }

interface InferencePlayer {
  isVideoAttached: Ref<boolean>
  isVideoPlaying: Ref<boolean>
  isVideoPaused: Ref<boolean>

  mediaTime: Ref<number>
  totalVideoSec: Ref<number>

  inferenceLoadingProgress: Ref<number>
  areInferencesLoading: Ref<boolean>
  areInferencesAvailable: Ref<boolean>
  inference?: Ref<TimestampedInference | undefined>
  allTimestampedActiveStep?: Ref<string | undefined>
  allTimestampedStepEventStatuses? : Ref<TimestampedAiStepEvent[] | undefined>
  timestampedStepEvents? : Ref<TimestampedAiStepEvent[] | undefined>
  timestampedActiveStep? : Ref<TimestampedActiveStep | undefined>

  videoProgress: Ref<number>

  init: (video: HTMLVIDEOElement, sessionId: string, unitId: string, procedure: AiProcedure) => void,

  previousFrame: () => void
  previousSecond: () => void
  nextFrame: () => void
  nextSecond: () => void

  play: () => void
  pause: () => void

  goToFrame: (timestamp: number) => void,
  requestVideoFrameCallback: (callback: (now: number, metadata: FrameMetadata) => void) => void,
  isEventListVisible: Ref<boolean>
  isLegendListVisible : Ref<boolean>
  isActiveInfoVisible : Ref<boolean>
  toggleEventsListVisibility: () => void
  toggleLegendListVisibility: () => void
  toggleActiveInfoVisibility: () => void
}

export const useInferencePlayer = (): InferencePlayer => {
  const isVideoAttached = ref(false)
  const isVideoPlaying = ref(false)
  const isVideoPaused = ref(true)

  const totalVideoSec = ref(0)

  const videoDimensions = reactive({ width: 0, height: 0 })

  const areInferencesLoading = ref(true)
  const areInferencesAvailable = ref(false)
  const allInferences = ref<TimestampedInference[]>([])
  const inference = ref<TimestampedInference>()
  const allTimestampedStepEventStatuses = ref<TimestampedAiStepEvent[]>([])
  const timestampedStepEvents = ref<TimestampedAiStepEvent[]>([])
  const allTimestampedActiveSteps = ref<TimestampedActiveStep[]>([])
  const timestampedActiveStep = ref<TimestampedActiveStep>()

  const avgFrameThreshold = ref(0)

  const procedure = ref({} as AiProcedure)

  const videoNode = ref<HTMLVIDEOElement | null>(null)
  const videoProgressPercentage = ref(0)
  const mediaTime = ref(0)
  const isEventListVisible = ref(true)
  const isLegendListVisible = ref(false)
  const isActiveInfoVisible = ref(false)

  const onVideoFrameCallback = (now: number, metadata: FrameMetadata) => {
    if (videoNode.value === null) return
    mediaTime.value = metadata.mediaTime
    videoNode.value.requestVideoFrameCallback(onVideoFrameCallback)

    pickInferencesToShow(metadata)
    pickStepEventsToShow(metadata)
    pickActiveStepToShow(metadata)
    countAndSetVideoProgress(metadata)
  }

  const init = (video: HTMLVIDEOElement, sessionId: string, unitId: string, procedureItem: AiProcedure): void => {
    console.log('init inference player', video, sessionId, unitId, procedureItem)
    videoNode.value = video

    videoNode.value.addEventListener('loadedmetadata', (): void => {
      if (videoNode.value === null) return
      isVideoAttached.value = true

      totalVideoSec.value = Math.round(videoNode.value.duration)

      videoDimensions.width = videoNode.value.videoWidth
      videoDimensions.height = videoNode.value.videoHeight
    })

    videoNode.value.requestVideoFrameCallback(onVideoFrameCallback)

    procedure.value = procedureItem
    fetchInferences(sessionId, unitId)
  }

  function pickActiveStepToShow(metadata: FrameMetadata) {
    let activeStep: TimestampedActiveStep = { timestamp: 0, step: '0-0' }
    const { mediaTime } = metadata
    for (const timestampedActiveStep of allTimestampedActiveSteps.value) {
      const { timestamp } = timestampedActiveStep
      const belowMaxTimestamp = timestamp < mediaTime + (avgFrameThreshold.value / 2 * 1.25)
      if (!belowMaxTimestamp) {
        break
      } else {
        activeStep = timestampedActiveStep
      }
    }
    timestampedActiveStep.value = activeStep
  }

  function pickStepEventsToShow(metadata: FrameMetadata) {
    const filteredStepEvents = []
    const { mediaTime } = metadata
    for (const timestampedStepEvent of allTimestampedStepEventStatuses.value) {
      const { timestamp } = timestampedStepEvent
      const shouldInferenceBeDisplayed = timestamp < mediaTime + (avgFrameThreshold.value / 2 * 1.25)
      if (!shouldInferenceBeDisplayed) {
        timestampedStepEvents.value = filteredStepEvents
        break
      }
      filteredStepEvents.push(timestampedStepEvent)
    }
  }

  const pickInferencesToShow = (metadata: FrameMetadata) => {
    let filteredInference
    for (const inference of allInferences.value) {
      const { timestamp } = inference
      const { mediaTime } = metadata
      const aboveMinTimestamp = timestamp >= mediaTime - (avgFrameThreshold.value / 2 * 1.25)
      if (!aboveMinTimestamp) {
        continue
      }

      const belowMaxTimestamp = timestamp < mediaTime + (avgFrameThreshold.value / 2 * 1.25)
      const shouldInferenceBeDisplayed = belowMaxTimestamp && aboveMinTimestamp

      if (shouldInferenceBeDisplayed) {
        const { frames } = inference
        const modifiedFrames = frames.map(frame => {
          const { bbox } = frame

          const box = bbox as AiBbox
          const { width, height } = metadata
          return {
            ...frame,
            bbox: {
              x: (box.xMin as number) * 100 / width,
              y: (box.yMin as number) * 100 / height,
              width: (box.xMax as number) * 100 / width - (box.xMin as number) * 100 / width,
              height: (box.yMax as number) * 100 / height - (box.yMin as number) * 100 / height,
              color: '',
            },
          }
        })

        filteredInference = {
          ...inference,
          frames: modifiedFrames,
        }

        break
      }
      if (!belowMaxTimestamp) {
        break
      }
    }

    // Update currently viewed inference
    if (filteredInference) {
      inference.value = filteredInference
    } else {
      inference.value = undefined
    }
  }

  // How fetch inferences works:
  // 1. Fetch Unit, get startPts and endPts values from it, and get videoSegments array, taking first element from it
  // 2. From videoSegments array, get videoId, using which fetch Video
  // 3. From Video, get startPts
  // 4. Get Inferences using numbered unit startPts and endPts values.

  const inferenceLoadingProgress = ref(0)

  const fetchInferences = async (sessionId: string, unitId: string) => {
    const { data: unit } = await api.units.unitsGetUnit({
      unit: unitId,
      session: sessionId,
    })

    const {
      startPts: unitStartPts,
      endPts: unitEndPts,
    } = unit

    const numberedStartPts = Number(unitStartPts)
    const numberedEndPts = Number(unitEndPts)

    const { videoSegments } = unit
    const areVideoSegmentsMissing = !(videoSegments as string[]).length
    if (areVideoSegmentsMissing) {
      areInferencesLoading.value = false
      areInferencesAvailable.value = false
      return
    }

    const [segment] = videoSegments as string[]
    const [, , , videoId] = segment.split('/')
    const { data: video } = await api.videos.videosGetVideo({
      video: videoId,
      session: sessionId,
    })

    const { startPts } = video

    const { data: { inferences } } = await (async () => {
      const pageSize = 1000
      let buffer: AiInference[] = []
      const data = { inferences: [] as AiInference[], pageToken: '1' }
      do {
        buffer = await api.inferences.inferencesListInferences({
          session: sessionId,
          readMask: 'pts,stepEvents,stepDetections,tracks,operatorGuidance',
          pageSize: pageSize,
          pageToken: data.pageToken,
          filter: JSON.stringify({
            $and: [
              { pts: { $gte: numberedStartPts } },
              { pts: { $lte: numberedEndPts } },
              { tracks: { $exists: true } },
              /*, FIXME: remove comment whenever manufacturer filter starts working again
              { manufacturer: { $in: usersStore.userGroup } }, */
            ],
          }),
        }).then(data => data.data.inferences) || []
        if (buffer.length < pageSize) {
          inferenceLoadingProgress.value = 1
        } else {
          const pts = +(buffer[buffer.length - 1]?.pts || 0)
          inferenceLoadingProgress.value = pts / numberedEndPts
        }
        data.inferences.push(...buffer)
        data.pageToken = `${+data.pageToken + 1}`
      } while (buffer.length === pageSize)
      return { data }
    })()
    // sorting cause inferences may come out of order
    inferences.sort((a, b) => Number(a.pts) - Number(b.pts))

    const VIDEO_MULTIPLIER = Math.pow(10, 9)

    const eventStates: TimestampedAiStepEvent[] = []
    for (const inference of inferences as AiInference[]) {
      const { pts, stepEvents } = inference
      const framePts = +(pts as string) - +(startPts as string)
      const timestamp = framePts / VIDEO_MULTIPLIER
      eventStates.push(...(stepEvents ?? []).map(stepEvent => ({ timestamp, ...stepEvent })))
    }
    // todo do a gather on each of the statuses....
    const stepsDict: Record<string, StepStepStatus> = eventStates.reduce((acc, { step }) => {
      acc[step ?? 'UNKNOWN'] = StepStepStatus.Incomplete
      return acc
    }, {} as Record<string, StepStepStatus>)
    // todo get unique timestamps
    const groupedByTimestamp: Record<number, TimestampedAiStepEvent[]> = eventStates.reduce((acc, event) => {
      if (!acc[event.timestamp]) {
        acc[event.timestamp] = []
      }
      acc[event.timestamp].push(event)
      return acc
    }, {} as Record<number, TimestampedAiStepEvent[]>)
    const changedEvents: TimestampedAiStepEvent[] = []

    for (const inference of inferences) {
      const { pts } = inference
      const framePts = Number(pts) - Number(startPts)
      const timestamp = framePts / VIDEO_MULTIPLIER
      if (groupedByTimestamp[timestamp]) {
        for (const event of groupedByTimestamp[timestamp]) {
          const eventStep = event.step ?? 'UNKNOWN_STEP'
          if (event.status !== stepsDict[eventStep] && event.status !== StepStepStatus.Unknown && event.status !== StepStepStatus.Active) {
            stepsDict[eventStep] = event.status as StepStepStatus
            changedEvents.push(event)
          }
        }
      }
    }
    allTimestampedStepEventStatuses.value = changedEvents

    const activeSteps = []
    for (const inference of inferences as AiInference[]) {
      const { pts } = inference
      const framePts = +(pts as string) - +(startPts as string)
      const timestamp = framePts / VIDEO_MULTIPLIER
      activeSteps.push({
        timestamp,
        step: inference.operatorGuidance?.activeStep ?? '0-0',
      })
    }
    allTimestampedActiveSteps.value = activeSteps

    const procedureSteps = procedure.value?.steps || []
    const inferencesObjects = (inferences as AiInference[]).map((inference) => {
      const { pts, tracks, operatorGuidance, stepDetections } = inference
      const activeMacro = (operatorGuidance?.activeStep)?.match(/-?\d+/)?.[0]
      const activeMacroNumber = Number(activeMacro)
      const relevantSteps = procedureSteps.filter(step => step.numberMacro === activeMacroNumber)
      const relevantGuidanceSteps = relevantSteps.flatMap(step => step.guidanceObjects)
      const relevantStepIndicators = relevantSteps.flatMap(step => step.stepIndicators)
      const allGuidanceObjects = procedureSteps.flatMap(step => step.guidanceObjects)

      const frames = []
      for (const track of tracks as AiTrack[]) {
        const { label, bbox } = track
        const trackBbox = bbox as AiBbox

        let color = 'yellow'
        if (relevantStepIndicators.includes(label as string)) {
          color = 'yellow'
        } else if (relevantGuidanceSteps.includes(label as string)) {
          color = 'purple'
        } else if (allGuidanceObjects.includes(label as string)) {
          color = 'cyan'
        }

        const stepDetection = (stepDetections as AiStepDetection[])
          .sort((a) => a.status === 'COMPLETE' ? -1 : 1)
          .find(stepDetection =>
            (stepDetection.bbox as AiBbox).xMin === trackBbox.xMin &&
            (stepDetection.bbox as AiBbox).xMax === trackBbox.xMax &&
            (stepDetection.bbox as AiBbox).yMin === trackBbox.yMin &&
            (stepDetection.bbox as AiBbox).yMax === trackBbox.yMax,
          )
        const stepId = stepDetection ? stepDetection.step : (track.object ?? '').replace(/.*\./, '*')

        frames.push({
          step: stepId as string,
          bbox: trackBbox,
          label: label as string,
          color,
        })
      }

      const framePts = +(pts as string) - +(startPts as string)

      return {
        timestamp: framePts / VIDEO_MULTIPLIER,
        frames,
      }
    })

    const timestamps = inferencesObjects.map(inference => inference.timestamp)

    const differences = timestamps.slice(1).map((x, i) => Math.abs(x - timestamps[i]))
    avgFrameThreshold.value = getMedianValue(differences)
    console.log('avgFrameThreshold', avgFrameThreshold.value)
    allInferences.value = inferencesObjects

    areInferencesAvailable.value = true
    areInferencesLoading.value = false
  }

  const countAndSetVideoProgress = (meta: FrameMetadata) => {
    if (videoNode.value === null) return
    const currentTime = meta.mediaTime
    const duration = videoNode.value.duration

    videoProgressPercentage.value = (currentTime / duration) * 100
  }

  const previousFrame = () => {
    if (videoNode.value === null) return
    videoNode.value.currentTime = Math.round((videoNode.value.currentTime - 0.2) * 100) / 100
  }

  const previousSecond = () => {
    if (videoNode.value === null) return
    videoNode.value.currentTime = Math.round((videoNode.value.currentTime - 1) * 100) / 100
  }

  const nextFrame = () => {
    if (videoNode.value === null) return
    videoNode.value.currentTime = Math.round((videoNode.value.currentTime + 0.2) * 100) / 100
  }
  const nextSecond = () => {
    if (videoNode.value === null) return
    videoNode.value.currentTime = Math.round((videoNode.value.currentTime + 1) * 100) / 100
  }

  const play = () => {
    if (videoNode.value === null) return
    isVideoPlaying.value = true
    isVideoPaused.value = false
    videoNode.value.play()
  }

  const pause = () => {
    if (videoNode.value === null) return
    isVideoPlaying.value = false
    isVideoPaused.value = true
    videoNode.value.pause()
  }

  const goToFrame = (frameTimestamp: number) => {
    if (videoNode.value === null) return
    videoNode.value.currentTime = frameTimestamp
  }

  const requestVideoFrameCallback = (callback: VideoFrameRequestCallback) => {
    if (videoNode.value === null) return
    videoNode.value.requestVideoFrameCallback(callback)
  }

  const toggleEventsListVisibility = () => {
    if (videoNode.value === null) return
    isEventListVisible.value = !isEventListVisible.value
  }

  const toggleLegendListVisibility = () => {
    if (videoNode.value === null) return
    isLegendListVisible.value = !isLegendListVisible.value
  }

  const toggleActiveInfoVisibility = () => {
    if (videoNode.value === null) return
    isActiveInfoVisible.value = !isActiveInfoVisible.value
  }

  return {
    isVideoAttached,
    isVideoPlaying,
    isVideoPaused,

    mediaTime,
    totalVideoSec,

    inferenceLoadingProgress,
    areInferencesLoading,
    areInferencesAvailable,

    videoProgress: videoProgressPercentage,

    init,

    inference,
    timestampedStepEvents,
    timestampedActiveStep,

    previousFrame,
    previousSecond,
    nextFrame,
    nextSecond,

    play,
    pause,

    goToFrame,
    requestVideoFrameCallback,
    isEventListVisible,
    isLegendListVisible,
    isActiveInfoVisible,
    toggleEventsListVisibility,
    toggleLegendListVisibility,
    toggleActiveInfoVisibility,
  }
}
